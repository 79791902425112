import React, { useState } from "react"
import "../assets/styles/index.scss"
import Head from "../components/global/head"
import ModalVideo from "react-modal-video"

import banner2x from "../assets/images/four-n-twenty/banner2@2x.jpg"
import banner3x from "../assets/images/four-n-twenty/banner3@2x.jpg"
import block2 from "../assets/images/four-n-twenty/block2.jpg"
import block3 from "../assets/images/four-n-twenty/block3.jpg"
import block4 from "../assets/images/four-n-twenty/block4.jpg"
import block5 from "../assets/images/four-n-twenty/block5.jpg"
import screen1 from "../assets/images/four-n-twenty/screen1.png"

const FourNTwenty = () => {
  const [showFullStudy, setShowFullStudy] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  const handleOpenVideo = (e) => {
    e.preventDefault()
    setShowVideo(true)
  }
  const handleCloseVideo = () => {
    setShowVideo(false)
  }

  return (
    <>
      <Head
        description="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
        title="Case Study - Four 'n' Twenty"
        url="https://hardhat.com.au/four-n-twenty"
        image="https://hardhat.com.au/images/og.jpg"
        bodyClassName="old-case-studies"
      />
      <div>
        <div className="container case-study dark-header">
          <section className="hhd-head-banner js-banner-intro-animation">
            <h1>Four’N Twenty - Save Our Slang</h1>
            <div className="divider black center" />
          </section>
          <section className="hhd-head-banner no-padding js-banner-intro-animation">
            <div
              className="banner-image"
              style={{
                backgroundImage: `url(${banner2x})`,
              }}
            >
              Four&#39;N Twenty Save Our Slang
            </div>
          </section>
          <section className="hhd-case-intro js-fade-in-animation">
            <div className="hhd-case-intro-text">
              <p>
                Four’N Twenty meat pies, an Aussie icon, was losing its shine
                after going quiet for some years. To make it more topical and
                restore pride in the brand, Hardhat developed the “Save our
                Slang” campaign, with calls for entries, Facebook and YouTube
                posts/video content, and special packaging featuring fair-dinkum
                “Strine”.
              </p>
              <hr />
            </div>
            <div className="hhd-case-intro-more">
              <div
                className={`hhd-case-intro-more__full ${
                  showFullStudy ? "show" : "hide"
                }`}
              >
                <br />
                <br />
                <h4>THE CHALLENGE</h4>
                <p>
                  Four’N Twenty meat pies are an iconic part of Australian
                  culture. An expected part of any party or sporting event … at
                  least in the past.
                </p>
                <p>
                  With society becoming more “global”, brands like Four’N Twenty
                  are in danger of becoming an anachronism. We wanted to get the
                  brand talked about and as an Aussie icon, reignite some Aussie
                  pride.
                </p>
                <p>
                  After years off air, it was time to find a way back into the
                  hearts, minds and stomachs of consumers around the nation.
                </p>
                <h4>THE WORK</h4>
                <p>
                  When you’re talking Aussie pride, well let’s face it, a big
                  part of it is talking like a true-blue fair-dinkum Aussie.
                </p>
                <p>
                  We made use of two brand resources that played a significant
                  part in magnifying the campaign, firstly the packaging itself
                  and secondly the popular brand ambassador Jonathan “Browny”
                  Brown.
                </p>
                <p>The campaign was in two phases.</p>
                <p>
                  The first phase was a “call for entries” on social media which
                  drove traffic to a microsite where the public voted on the
                  slang terms they wanted to save. Thousands of entries were
                  received with the most popular “She’ll Be Right”, “Strewth”,
                  “Drongo”, “Ripsnorter”, “Bogan” and “You Beauty!”
                </p>
                <p>
                  The most iconic slang words were chosen and featured
                  prominently on the packaging, giving consumers more reasons to
                  buy more packs, as well as take photos and tag mates on social
                  media as 'bogans' or 'Sheilas'.
                </p>
                <p>
                  When consumers bought their favourite packs, the second phase
                  of the campaign encouraged our audience to go to the website
                  to enter their barcode to win branded Four'N Twenty
                  merchandise - stubby holders, caps and budgie smugglers.
                </p>
                <h4>THE RESULTS</h4>
                <p>
                  The prime objective of getting Australians to talk about
                  Four’N Twenty was clearly achieved with enormous community
                  engagement as well as the free PR generated.
                </p>
                <p>
                  Four thousand people told us what slang they wanted to save
                  and then hundreds of thousands got involved in the campaign
                  itself. Famed comedian Chris Franklin even contacted Four’N
                  Twenty requesting to be part of it all.
                </p>
                <p>
                  People happily posted photos of themselves with Four’N Twenty
                  packs proclaiming themselves as “Bogan”, “Flat Chat” and “Far
                  Out”… among others. Maybe not surprisingly many chose to tag
                  their friends in a like manner, pointing out, humorously, what
                  they thought of them.
                </p>
                <p>
                  During the 12 week campaign period, close to a million people
                  were reached organically (across Facebook and YouTube).
                </p>
                <p>
                  The five YouTube videos collectively achieved over 600,000
                  views in the campaign period.
                </p>
                <p>
                  Overall engagement was 13% on Facebook, more than double the
                  industry average.
                </p>
                <p>
                  The launch video on Facebook achieved over 19,800 views,
                  driving the highest reach and traffic to our “Save Our Slang”
                  microsite. This also generated almost 400 comments, with over
                  90% of the comments positive. Clearly we had successfully
                  tapped into Aussie pride to the benefit of Four’N Twenty. As
                  one person commented “this ripper of a competition has got our
                  names fair dinkum written all over it cobber”.
                </p>
                <p>
                  The enormous popularity of the campaign led it to generate
                  tens of thousands of dollars worth of free PR.
                </p>
              </div>
            </div>
            <a
              onClick={() => setShowFullStudy(!showFullStudy)}
              href="#"
              className="case-study-more button black button-partnership no-wrapper"
            >
              {!showFullStudy ? "READ FULL CASE STUDY" : "-"}
            </a>
          </section>
          <section className="hhd-case-image-block js-fade-in-animation">
            <div className="block-half">
              <div className="block-half">
                <img src={`${block2}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block3}`} width="100%" />
              </div>
              <div className="block-full">
                <img src={`${block4}`} width="100%" />
              </div>
            </div>
            <div className="block-half">
              <img src={`${block5}`} width="100%" />
            </div>
          </section>
          <section className="hhd-case-screen-breakout hhd-case-margin-bottom hhd-case-light_grey js-fade-in-animation split-images">
            <img alt="" src={`${screen1}`} width="100%" />
          </section>
          <section className="hhd-case-bigscreen-video no-padding js-fade-in-animation">
            <img alt="" src={`${banner2x}`} width="100%" />
          </section>
          <section className="hhd-case-bigscreen-video js-hhd-video no-padding">
            <button className="play-vid" onClick={handleOpenVideo}>
              {" "}
              <div className="js-video-container latest-work-video-container">
                <img src={banner3x} width="100%" />
              </div>
              <span className="close-vid">
                <a href="#" className="close-vid-btn">
                  <i className="icon--stop" />
                </a>
              </span>
            </button>
            <ModalVideo
              channel="youtube"
              isOpen={showVideo}
              videoId="XizQEDrTyVc"
              onClose={handleCloseVideo}
            />
          </section>
        </div>
      </div>
    </>
  )
}
export default FourNTwenty
